<template>
<div style="max-width: 800px">

  <div class="card-box">
    <h4>Top Domains</h4>
    <p>View the top domains on all of the different radars</p>
    <CButton @click="loadPath('sms-radar/domains')" color="info" class="card-box-button">
      Top Domains
    </CButton>
  </div>

  <div class="card-box">
    <h4>Top Messages</h4>
    <p>View the top messages on all of the different radars</p>
    <CButton @click="loadPath('sms-radar/messages')" color="info" class="card-box-button">
      Top Messages
    </CButton>
  </div>

  <div class="card-box">
    <h4>Top Senders</h4>
    <p>View the top senders on all of the different radars</p>
    <CButton @click="loadPath('sms-radar/senders')" color="info" class="card-box-button">
      Top Senders
    </CButton>
  </div>

  <br>

  <div class="card-box">
    <h4>Live Domains</h4>
    <p>Live look at the domains we're seeing right now</p>
    <CButton @click="loadPath('sms-radar/live-domains')" color="info" class="card-box-button">
      Live Domains
    </CButton>
  </div>

  <div class="card-box">
    <h4>Live Senders</h4>
    <p>Live look at the senders we're seeing right now</p>
    <CButton @click="loadPath('sms-radar/live-senders')" color="info" class="card-box-button">
      Live Senders
    </CButton>
  </div>

</div>
</template>

<script>
export default {
  name: 'SMS-Radar',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
